<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.hub_id" v-validate="{ required: true }"  id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub is required</div>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-sm text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Purpose Type</th>
              <th>Requisite Liter</th>
              <th style="color: red">Remaining Liter</th>
              <th>Created At</th>
              <th>Status</th>
              <th>Fuel Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition, index) in requisitions.data" :key="index">
              <td>{{ requisitions.from + index}}</td>
              <td>{{ requisition.fuel_purpose_type.fuel_purpose_type_desc }}</td>
              <td>{{ requisition.requisite_liter }}</td>
              <td style="color: red">{{ remainingFuel(requisition.requisite_liter, requisition.hub_fuel_consumption_total) }}</td>
              <td>{{ customDate(requisition.created_at) }}</td>
              <td>
                <a-tag color="#f50" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && !requisition.fuel_requisition_status.includes('ADMIN_APPROVED') && !requisition.fuel_requisition_status.includes('ADMIN_REJECTED') ">Pending</a-tag>
                <a-tag color="#f50" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && requisition.fuel_requisition_status.includes('ADMIN_REJECTED')">Rejected</a-tag>
                <a-tag color="#87d068" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && requisition.fuel_requisition_status.includes('ADMIN_APPROVED')">Approved</a-tag>
                <a href="javascript: void(0);" v-b-modal.fuel-requisition-status-modal @click="$bvModal.show('fuel-requisition-status-modal'), status(requisition.max_fuel_requisition_status, requisition)" class="btn btn-sm btn-info">
                  <i class="fa fa-level-up"/>
                </a>
              </td>
              <td>
                <a-tag color="#f50" v-if="requisition.fuel_collection_status == 'Collect'">Collect</a-tag>
                <a-tag color="#87d068" v-else>Collected</a-tag>
              </td>
              <td>
                <router-link :to="{ name: 'hubFuelRequisitionView', params: { fuel_requisition_id: requisition.id } }" class="btn btn-sm btn-info mr-1" tag="a">
                  <i class="fe fe-eye"/> View
                </router-link>
                <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="consumptionHistory(requisition.hub_fuel_consumption_total, requisition.hub_fuel_consumptions)"><i class="fa fa-info mr-1"></i>Details</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="requisitions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="requisitions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <consumption-history
      :hub_fuel_consumptions="hub_fuel_consumptions"
      :hub_fuel_consumption_total="hub_fuel_consumption_total"
      @search="search"
    ></consumption-history>
    <status :current="current" :requisition="requisition"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import consumptionHistory from '@/views/fuelConsumption/modal/consumption_history'
import Status from '@/views/fuelConsumption/modal/status'

export default {
  name: 'List',
  components: { Empty, consumptionHistory, Status },
  data() {
    return {
      hubs: [],
      hub_fuel_consumptions: {},
      hub_fuel_consumption_total: '',
      remaining_fuel: '',
      requisitions: {},
      requisition: {},
      loading: false,
      loader: false,
      flag: false,
      btnLoading: false,
      btnCollected: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      current: 0,
      search_data: {
        date_range: '',
        hub_id: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/consumption/codes')
        .then(response => {
          this.hubs = response.data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loading = true
          this.btnLoading = true
          this.flag = true
          apiClient.post('api/admin/hub/fuel-stock/consumption-history', this.search_data)
            .then(response => {
              this.loading = false
              this.btnLoading = false
              this.flag = true
              this.requisitions = response.data.requisitions
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    getResults(page = 1) {
      apiClient.post('api/admin/hub/fuel-stock/consumption-history?page=' + page, this.search_data)
        .then(response => {
          this.requisitions = response.data.requisitions
        })
    },
    consumptionHistory(hubFuelConsumptionTotal, hubFuelConsumptions) {
      this.hub_fuel_consumption_total = ''
      this.hub_fuel_consumptions = {}
      this.hub_fuel_consumption_total = hubFuelConsumptionTotal
      this.hub_fuel_consumptions = hubFuelConsumptions
      this.$bvModal.show('fuel-consumption-history-modal')
    },
    remainingFuel(requisiteLiter, hubFuelConsumptionTotal) {
      return parseFloat(requisiteLiter) - parseFloat(hubFuelConsumptionTotal)
    },
    status(fuelRequisitionStatus, requisition) {
      this.current = 0
      this.requisition = ''
      this.current = fuelRequisitionStatus
      this.requisition = requisition
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
