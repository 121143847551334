<template>
  <div>
    <b-modal id="fuel-consumption-history-modal" title="Fuel Consumption History" size="lg" hide-footer>
      <div class="modal-content bg-white rounded">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-bordered">
            <thead>
            <tr>
              <th>#</th>
              <th>Entry User</th>
              <th>Fuel Liter</th>
              <th>Entry Date</th>
              <th>Generator Reading</th>
              <th>Fuel Refilling</th>
              <th v-if="user.role.includes('hub_in_charge')">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(hub_fuel_consumption, index) in hub_fuel_consumptions' :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ hub_fuel_consumption.user.name }}</td>
              <td>{{ hub_fuel_consumption.fuel_liter }}</td>
              <td>{{ hub_fuel_consumption.entry_date }}</td>
              <td>
                <a :href="hub_fuel_consumption.generator_reading_url" target="_blank" download>
                  <img :src="hub_fuel_consumption.generator_reading_url" alt="" class="rounded mx-auto d-block" width="40" height="40"/>
                </a>
              </td>
              <td>
                <a :href="hub_fuel_consumption.fuel_refilling_url" target="_blank" download>
                  <img :src="hub_fuel_consumption.fuel_refilling_url" alt="" class="rounded mx-auto d-block" width="40" height="40"/>
                </a>
              </td>
              <td v-if="user.role.includes('hub_in_charge')">
<!--                <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(hub_fuel_consumption.id)"><i class="fe fe-trash mr-1"></i>Delete</a>-->
              </td>
            </tr>
            <tr>
              <td></td>
              <td><strong>Total</strong></td>
              <td>{{ hub_fuel_consumption_total }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td v-if="user.role.includes('hub_in_charge')"></td>
            </tr>
            </tbody>
          </table>
          <h5 class="text-center text-secondary" v-if="hub_fuel_consumptions.length === 0">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import { mapGetters } from 'vuex'

export default {
  components: { Empty },
  props: ['hub_fuel_consumptions', 'hub_fuel_consumption_total'],
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    onDelete(Id) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/hub/consumption/fuel-requisition/' + Id).then(response => {
          if (!response.error) {
            if (response.data.error === false) {
              this.$emit('search')
              this.$bvModal.hide('fuel-consumption-history-modal')
              this.$notification.success({
                message: response.data.message,
              })
            } else {
              this.$notification.error({
                message: 'Deleted Failed',
              })
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
  },
}
</script>
<style></style>
